import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";

import { Layout, SEO } from "../components";

const PrivacyPolicyPage = () => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        ...SiteInformationFragment
      }
    }
  `);

  const metadata = site.siteMetadata;
  const metaPage = "aviso-de-privacidad";
  const metaTitle = "Aviso de privacidad";
  const metaDescription =
    "Este Aviso de Privacidad incluye información importante sobre sus datos personales, recomendamos que la lea detenidamente.";

  const schemaMarkup = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebSite",
        "@id": metadata.siteUrl,
        url: metadata.siteUrl,
        name: metadata.title,
        description: metaDescription,
        inLanguage: "es-MX",
      },
      {
        "@type": "ImageObject",
        "@id": `${metadata.siteUrl}${metadata.defaultOgImage}#primaryimage`,
        inLanguage: "es-MX",
        url: `${metadata.siteUrl}${metadata.defaultOgImage}`,
      },
      {
        "@type": "WebPage",
        "@id": `${metadata.siteUrl}/${metaPage}/#webpage`,
        url: `${metadata.siteUrl}/${metaPage}`,
        name: `${metaTitle} · ${metadata.title}`,
        isPartOf: { "@id": metadata.siteUrl },
        primaryImageOfPage: {
          "@id": `${metadata.siteUrl}${metadata.defaultOgImage}`,
        },
        breadcrumb: {
          "@id": `${metadata.siteUrl}/${metaPage}/#breadcrumb`,
        },
        inLanguage: "es-MX",
        potentialAction: [
          {
            "@type": "ReadAction",
            target: [`${metadata.siteUrl}/${metaPage}`],
          },
        ],
      },
      {
        "@type": "BreadcrumbList",
        "@id": `${metadata.siteUrl}/${metaPage}#breadcrumb`,
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@type": "WebPage",
              "@id": metadata.siteUrl,
              url: metadata.siteUrl,
              name: "Inicio",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@type": "WebPage",
              "@id": `${metadata.siteUrl}/${metaPage}`,
              url: `${metadata.siteUrl}/${metaPage}`,
              name: `${metaTitle} · ${metadata.title}`,
            },
          },
        ],
      },
    ],
  };

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDescription}
        schemaMarkup={schemaMarkup}
      />
      <div className="px-4 py-10 max-w-3xl mx-auto sm:px-6 sm:py-12 lg:max-w-4xl lg:py-16 lg:px-8 xl:max-w-6xl">
        <div className="mt-8 prose-sm sm:prose lg:prose-lg xl:prose-xl mx-auto text-justify">
          <p>
            <strong>AVISO DE PRIVACIDAD </strong>
          </p>

          <p>
            EN ATENCIÓN A LA LEY FEDERAL DE PROTECCIÓN DE DATOS PERSONALES EN
            POSESIÓN DE LOS PARTICULARES (EN LO SUCESIVO “LEY”) VIGENTE EN LOS
            ESTADOS UNIDOS MEXICANOS, LE INFORMAMOS QUE ÉSTE AVISO DE PRIVACIDAD
            DE DATOS PERSONALES (EN LO SUCESIVO “AVISO”) DESCRIBE COMO BRIGHT
            EXCHANGE SOCIEDAD ANÓNIMA PROMOTORA DE INVERSIÓN DE CAPITAL VARIABLE
            Y SUBSIDIARIAS Y/O SUS UNIDADES DE NEGOCIO (EN LO SUCESIVO “BRIGHT
            EXCHANGE” O “LA EMPRESA”) CON DOMICILIO EN: ZAMORA 187, CONDESA,
            CUAUHTÉMOC, 06140, CIUDAD DE MÉXICO, D.F., MÉXICO, PODRÍAN
            RECOLECTAR Y USAR INFORMACIÓN PROPORCIONADA POR USTED EN ESTE
            WEBSITE (EN LO SUCESIVO “PORTAL”).
          </p>
          <p>
            1. LA EMPRESA RECOPILA SOLAMENTE LA INFORMACIÓN QUE EN ÉSTA PÁGINA
            SOLICITA CON LOS SIGUIENTES PROPÓSITOS: (A) FINES DE IDENTIFICACIÓN,
            (B) FINES ESTADÍSTICOS Y/O (C) PARA EVENTUALMENTE CONTACTARLO VÍA
            CORREO ELECTRÓNICO CON EL FIN DE RESPONDER A SU SOLICITUD Y
            MONITOREAR LA FRECUENCIA DE SU USO DE ESTE PORTAL PARA
            ADMINISTRACIÓN INTERNA Y PROPÓSITOS DE ANÁLISIS. EN LA RECOLECCIÓN
            DE DATOS PERSONALES SEGUIMOS TODOS LOS PRINCIPIOS QUE MARCA LA LEY
            (ARTÍCULO 6): LICITUD, CALIDAD, CONSENTIMIENTO, INFORMACIÓN,
            FINALIDAD, LEALTAD, PROPORCIONALIDAD Y RESPONSABILIDAD. (D) PARA DAR
            SEGUIMIENTO DE PAGOS, CONTACTO DEL SERVICIO Y/O LLAMARLE A EFECTOS
            DE DAR SEGUIMIENTO A SU SERVICIO.
          </p>
          <p>
            2. SUS DATOS PERSONALES JAMÁS SERÁN DIVULGADOS NI COMPARTIDOS CON
            TERCEROS (NO HABRÁ TRANSFERENCIA DE DATOS), NI SE LES DARÁ UN USO
            DISTINTO AL SEÑALADO EN EL PUNTO 1 ANTERIOR, SALVO QUE MEDIE UN
            CAMBIO EN ESTE AVISO DE PRIVACIDAD. NO OBSTANTE, NOSOTROS PODEMOS
            CONTRATAR A UN TERCERO EXTERNO PARA MANEJAR Y ADMINISTRAR ESTE
            PORTAL Y PARA QUE NOS APOYE A ENTENDER CÓMO LOS VISITANTES LO
            UTILIZAN. AÚN CUANDO ESTE TERCERO PUEDE RECOLECTAR INFORMACIÓN SOBRE
            USTED EN NUESTRO NOMBRE, NOSOTROS CONTROLAMOS EL USO DE ESA
            INFORMACIÓN DE ACUERDO CON ESTE AVISO. NO COMERCIALIZAMOS, VENDEMOS
            O RENTAMOS INFORMACIÓN PERSONAL SOBRE USTED A UN TERCERO SIN UN
            CONSENTIMIENTO PREVIO DE SU PARTE O A SOLICITUD EXPRESA DEL USUARIO
            COMO PUDIERA SER EN LOS CASOS DE FUSIÓN, ADQUISICIÓN O VENTA DE
            ACTIVOS QUE EFECTÚE LA EMPRESA.
          </p>
          <p>
            SE HACE LA ADVERTENCIA QUE LA EMPRESA PUEDE UTILIZAR “COOKIES” PARA
            CONFIRMAR SU IDENTIFICACIÓN, PERSONALIZAR SU ACCESO A ESTE PORTAL Y
            REVISAR SU USO, CON EL SOLO PROPÓSITO DE MEJORAR SU NAVEGACIÓN Y SUS
            CONDICIONES DE FUNCIONALIDAD, TODA VEZ QUE MEDIANTE LAS CITADAS
            “COOKIES” NO SE RECABAN DATOS PERSONALES DE LOS USUARIOS EN TÉRMINOS
            DE LA LEY. ESTE PORTAL TIENE LIGAS A OTROS SITIOS EXTERNOS, DE LOS
            CUALES EL CONTENIDO Y POLÍTICAS DE PRIVACIDAD NO SON RESPONSABILIDAD
            DE LA EMPRESA.
          </p>
          <p>
            3. EL FACILITADOR DE PROTECCIÓN DE DATOS PERSONALES POR CUENTA DEL
            RESPONSABLE DE ESTE PORTAL (WWW.THINKBRIGHT.MX) ES EL SEÑOR JONAH
            GREENBERGER.
          </p>
          <p>
            4. LOS USUARIOS DEL PRESENTE PORTAL TIENEN RECONOCIDOS Y PODRÁN
            EJERCITAR LOS DERECHOS “ARCO” (ACCESO, CANCELACIÓN, RECTIFICACIÓN Y
            OPOSICIÓN), ENVIANDO DIRECTAMENTE SU SOLICITUD AL RESPONSABLE A
            TRAVÉS DE LA CUENTA DE CORREO INFO@THINKBRIGHT.MX DICHA SOLICITUD
            DEBERÁ CONTENER: NOMBRE Y DOMICILIO U OTRO MEDIO PARA COMUNICARLE LA
            RESPUESTA A SU SOLICITUD; LOS DOCUMENTOS QUE ACREDITEN LA IDENTIDAD
            O, EN SU CASO, LA REPRESENTACIÓN LEGAL; LA DESCRIPCIÓN CLARA Y
            PRECISA DE LOS DATOS PERSONALES RESPECTO DE LOS QUE SE BUSCA EJERCER
            ALGUNO DE LOS DERECHOS “ARCO” Y CUALQUIER OTRO ELEMENTO QUE FACILITE
            LA LOCALIZACIÓN DE LOS DATOS PERSONALES.
          </p>
          <p>
            5. EN EL PRESENTE PORTAL, LA EMPRESA NO RECABA DATOS PERSONALES
            SENSIBLES DE LOS USUARIOS. NO OBSTANTE, SI SE DIERA EL CASO, SE
            OBTENDRÁ EL CONSENTIMIENTO EXPRESO Y POR ESCRITO DEL TITULAR PARA SU
            TRATAMIENTO, A TRAVÉS DE SU FIRMA AUTÓGRAFA, FIRMA ELECTRÓNICA, O
            CUALQUIER MECANISMO DE AUTENTICACIÓN.
          </p>
          <p>
            6. ESTE AVISO DE PRIVACIDAD CUMPLE CON LOS REQUISITOS QUE MARCA LA
            LEY (ARTÍCULOS 15 Y 16).
          </p>
          <p>
            7. EN CASO DE QUE EXISTA ALGÚN CAMBIO EN ESTE AVISO DE PRIVACIDAD,
            SE LE COMUNICARÁ A LOS USUARIOS DEL PORTAL DE LA SIGUIENTE MANERA:
            (A) ENVIÁNDOLE UN CORREO ELECTRÓNICO A LA CUENTA QUE HA REGISTRADO
            EN ESTE PORTAL Y/O (B) PUBLICANDO UNA NOTA VISIBLE EN NUESTRO
            PORTAL. NO SEREMOS RESPONSABLES SI USTED NO RECIBE LA NOTIFICACIÓN
            DE CAMBIO EN EL AVISO DE PRIVACIDAD SI EXISTIERE ALGÚN PROBLEMA CON
            SU CUENTA DE CORREO ELECTRÓNICO, DE TRANSMISIÓN DE DATOS POR
            INTERNET O SI NO SE TUVO ACCESO AL PORTAL POR CUALQUIER CAUSA NO
            IMPUTABLE A LA EMPRESA. POR SU SEGURIDAD, REVISE EN TODO MOMENTO QUE
            ASÍ LO DESEE EL CONTENIDO DE ESTE AVISO DE PRIVACIDAD EN NUESTRO
            PORTAL.
          </p>
          <p>
            NOS RESERVAMOS EL DERECHO DE CAMBIAR ESTE AVISO DE PRIVACIDAD EN
            CUALQUIER MOMENTO.
          </p>

          <p>
            LAS LEYES Y REGULACIONES DE DIFERENTES PAÍSES PUEDEN IMPONER
            DIFERENTES REQUERIMIENTOS (E INCLUSIVE CONFLICTIVOS) EN EL INTERNET
            Y LA PROTECCIÓN DE LA INFORMACIÓN. NOSOTROS ESTAMOS UBICADOS EN LOS
            ESTADOS UNIDOS MEXICANOS Y TODOS LOS ASUNTOS EN RELACIÓN A ESTE
            PORTAL SON REGIDOS POR LAS LEYES VIGENTES DE LOS ESTADOS UNIDOS
            MEXICANOS. SI USTED ESTÁ UBICADO EN ALGÚN OTRO PAÍS FUERA DE LOS
            ESTADOS UNIDOS MEXICANOS Y NOS CONTACTA, POR FAVOR TOME EN CUENTA
            QUE CUALQUIER INFORMACIÓN QUE USTED NOS PROVEA SERÁ TRANSFERIDA A
            LOS ESTADOS UNIDOS MEXICANOS, Y AL MOMENTO DE INGRESAR SU
            INFORMACIÓN USTED AUTORIZA ESTA TRANSFERENCIA Y LA ACEPTACIÓN DEL
            PRESENTE AVISO DE PRIVACIDAD.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default PrivacyPolicyPage;
